exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-page-js": () => import("./../../../src/pages/page/page.js" /* webpackChunkName: "component---src-pages-page-page-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-projekte-projekte-js": () => import("./../../../src/pages/projekte/projekte.js" /* webpackChunkName: "component---src-pages-projekte-projekte-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

